import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  //- 取品牌分类带缓存
  async categoriesCached() {
    const rs = await req.get({
      url: URLS.BRANDS_CATEGORIES_CACHED
    });
    return rs.data;
  },
  //- 取品牌分类
  async categories() {
    const rs = await req.get({
      url: URLS.BRANDS_CATEGORIES
    });
    return rs.data;
  },
  //- 创建
  async categoriesCreate(data) {
    const rs = await req.postJson({
      url: URLS.BRANDS_CATEGORIES_CREATE,
      data
    });
    return rs.data;
  },
  //- 更新
  async categoriesUpdate(data) {
    const rs = await req.postJson({
      url: URLS.BRANDS_CATEGORIES_UPDATE,
      data
    });
    return rs.data;
  },
  //- 删除
  async categoriesDelete(id) {
    const rs = await req.get({
      url: `${URLS.BRANDS_CATEGORIES_DELETE}/${id}`
    });
    return rs.data;
  },
  //- 创建或更新品牌
  async save(data) {
    const rs = await req.postJson({
      url: URLS.BRANDS_SAVE,
      data
    });
    return rs.data;
  },
  //- 删除
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.BRANDS_DELTE}/${id}`
    });
    return rs.data;
  },
  //- 查询品牌列表
  async list(data) {
    const rs = await req.postJson({
      url: URLS.BRANDS_LIST,
      data
    });
    return rs.data;
  },
  //- 取品牌详情
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.BRANDS_DETAIL}/${id}`
    });
    return rs.data;
  },
}