
export default {
  layout: "client",
  name: "page-layout-error",
  props: ["error"],
  head: {
    title: "发生错误",
    hid: "error",
  },
};
