
import layoutMixins from '~/mixins/layout';

export default {
  mixins: [layoutMixins],
  data() {
    return {
      keepAliveProps: {
        // 排除 page name，不进行缓存
        exclude: [
          "page-layout-error",
          "page-setup",
          "page-product-list",
          "page-manager-clue-index",
          "page-manager-index",
          "page-manager-announcement-list",
          "page-manager-brand-list",
          "page-manager-clue-user",
          "page-manager-owner-holding",
          "page-manager-owner-list",
        ]
      }
    }
  }
}
