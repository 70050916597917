import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  async bootstrap() {
    const rs = await req.get({
      url: URLS.BOOTSTRAP
    });
    return rs.data;
  },
  //- 取服务器时间
  async time() {
    const rs = await req.get({
      url: URLS.SYSTEM_SERVER_TIME
    });
    return rs.data;
  },
  //- 站点设置
  async setSite(data) {
    const rs = await req.postJson({
      url: URLS.SYSTEM_SET_SITE,
      data
    });
    return rs.data;
  },
  //- 数据总览
  async overview() {
    const rs = await req.get({
      url: URLS.SYSTEM_OVERVIEW
    });
    return rs.data;
  },
}