
import { mapState } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      direction: 'ltr',
      menus: [
        { name: "首页", path: "/" },
        // { name: "搜索", path: "/search" },
        { name: "销售团队", path: "/seller" },
      ]
    }
  },
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      isAdminUser: (state) => state.isAdminUser,
      loadingUserInfo: (state) => state.loadingUserInfo
    }),
    hasLogin() {
      const { user } = this;
      return this.$_.isEmpty(user) ? false : true;
    },
  },
  methods: {
    async handleBackToLoginPage() {
      await this.$store.dispatch("users/redirect")
    }
  }
}
