
import { mapState } from "vuex";
import layoutMixins from '~/mixins/layout';

export default {
  mixins: [layoutMixins],
  data() {
    return {
      keepAliveProps: {
        // 排除 page name，不进行缓存
        exclude: [
          "page-layout-error",
          "page-setup",
          "page-product-list",
          "page-manager-clue-index",
          "page-manager-index",
          "page-manager-announcement-list",
          "page-manager-brand-list",
          "page-manager-clue-user",
          "page-manager-owner-holding",
          "page-manager-owner-list",
        ]
      }
    }
  },
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      isAdminUser: (state) => state.isAdminUser,
      loadingUserInfo: (state) => state.loadingUserInfo
    }),
    /**
     * 是否已经登录
     */
    hasLogin() {
      const { user } = this;
      return this.$_.isEmpty(user) ? false : true;
    },
    // 管理员面板可见
    adminPanelVisible() {
      const { hasLogin, isAdminUser } = this;
      return hasLogin && isAdminUser;
    }
  },
  methods: {
    async handleBackToLoginPage() {
      await this.$store.dispatch("users/redirect")
    }
  }
}
