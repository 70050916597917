
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      isAdminUser: (state) => state.isAdminUser,
      loadingUserInfo: (state) => state.loadingUserInfo,
    }),
    /**
     * 是否已经登录
     */
    hasLogin() {
      const { user } = this;
      return this.$_.isEmpty(user) ? false : true;
    },
    avatarUrl() {
      const { user } = this;
      const defaultAvatarUrl = "/images/avatar.png";

      if (this.$_.isEmpty(user)) {
        return defaultAvatarUrl
      }

      return user.avatarUrl || defaultAvatarUrl;
    }
  },

  methods: {
    /**
     * 用户登录
     */
    async handleUserLogin() {
      await this.$store.dispatch("users/redirect");
    },
    /**
     * 点击
     * @param {*} command 
     */
    async handleCommand(command) {
      if (command == "logout") {

        this.$confirm('您真的确定退出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async () => {
          await this.$store.dispatch("users/logout");
        }).catch(() => {
        });

      }


      if (command == "manager") {
        this.$router.push('/manager');
      }
    }
  }
}
