import req from "@/utils/request";
import { URLS } from "@/utils/request/requestUrls";


export default {
  //- 保存或创建
  async wxLogin(data) {
    const rs = await req.postJson({
      url: URLS.MP_WEIXIN_LOGIN,
      data
    });
    return rs.data;
  },
  //- 绑定小程序
  async bind(data) {
    const rs = await req.postJson({
      url: URLS.MP_WEIXIN_BIND,
      data
    });
    return rs.data;
  },
  //- 解绑小程序
  async unbind() {
    const rs = await req.get({
      url: URLS.MP_WEIXIN_UNBIND,
    });
    return rs.data;
  },
  //- 绑定公众号
  async gzhBind(data) {
    const rs = await req.postJson({
      url: URLS.MP_WX_GZH_BIND,
      data
    });
    return rs.data;
  },
  //- 公众号登录
  async wxMPLogin(data) {
    const rs = await req.postJson({
      url: URLS.MP_WX_GZH_LOGIN,
      data
    });
    return rs.data;
  },
}