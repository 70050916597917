export const AnnouncementSaveButton = () => import('../../components/announcement/SaveButton.vue' /* webpackChunkName: "components/announcement-save-button" */).then(c => wrapFunctional(c.default || c))
export const BrandCategorySaveButton = () => import('../../components/brand/CategorySaveButton.vue' /* webpackChunkName: "components/brand-category-save-button" */).then(c => wrapFunctional(c.default || c))
export const BrandSaveButton = () => import('../../components/brand/SaveButton.vue' /* webpackChunkName: "components/brand-save-button" */).then(c => wrapFunctional(c.default || c))
export const BrandSelector = () => import('../../components/brand/Selector.vue' /* webpackChunkName: "components/brand-selector" */).then(c => wrapFunctional(c.default || c))
export const ClueCloseButton = () => import('../../components/clue/CloseButton.vue' /* webpackChunkName: "components/clue-close-button" */).then(c => wrapFunctional(c.default || c))
export const ClueDetailEditor = () => import('../../components/clue/DetailEditor.vue' /* webpackChunkName: "components/clue-detail-editor" */).then(c => wrapFunctional(c.default || c))
export const ClueListTile = () => import('../../components/clue/ListTile.vue' /* webpackChunkName: "components/clue-list-tile" */).then(c => wrapFunctional(c.default || c))
export const ClueProtectButton = () => import('../../components/clue/ProtectButton.vue' /* webpackChunkName: "components/clue-protect-button" */).then(c => wrapFunctional(c.default || c))
export const ClueSaveForm = () => import('../../components/clue/SaveForm.vue' /* webpackChunkName: "components/clue-save-form" */).then(c => wrapFunctional(c.default || c))
export const ClueSaveHistory = () => import('../../components/clue/SaveHistory.vue' /* webpackChunkName: "components/clue-save-history" */).then(c => wrapFunctional(c.default || c))
export const ClueStatusFilter = () => import('../../components/clue/StatusFilter.vue' /* webpackChunkName: "components/clue-status-filter" */).then(c => wrapFunctional(c.default || c))
export const ClueStatusLabel = () => import('../../components/clue/StatusLabel.vue' /* webpackChunkName: "components/clue-status-label" */).then(c => wrapFunctional(c.default || c))
export const ClueTypeLabel = () => import('../../components/clue/TypeLabel.vue' /* webpackChunkName: "components/clue-type-label" */).then(c => wrapFunctional(c.default || c))
export const ClueTypeSelector = () => import('../../components/clue/TypeSelector.vue' /* webpackChunkName: "components/clue-type-selector" */).then(c => wrapFunctional(c.default || c))
export const ClueClaimButton = () => import('../../components/clue/claimButton.vue' /* webpackChunkName: "components/clue-claim-button" */).then(c => wrapFunctional(c.default || c))
export const CraftSaveButton = () => import('../../components/craft/SaveButton.vue' /* webpackChunkName: "components/craft-save-button" */).then(c => wrapFunctional(c.default || c))
export const CraftSelector = () => import('../../components/craft/Selector.vue' /* webpackChunkName: "components/craft-selector" */).then(c => wrapFunctional(c.default || c))
export const IndexQuickActions = () => import('../../components/index/QuickActions.vue' /* webpackChunkName: "components/index-quick-actions" */).then(c => wrapFunctional(c.default || c))
export const NotificationSaveButton = () => import('../../components/notification/SaveButton.vue' /* webpackChunkName: "components/notification-save-button" */).then(c => wrapFunctional(c.default || c))
export const FilePicker = () => import('../../components/file/picker.vue' /* webpackChunkName: "components/file-picker" */).then(c => wrapFunctional(c.default || c))
export const OwnerInfoCard = () => import('../../components/owner/InfoCard.vue' /* webpackChunkName: "components/owner-info-card" */).then(c => wrapFunctional(c.default || c))
export const OwnerRemoteSearch = () => import('../../components/owner/RemoteSearch.vue' /* webpackChunkName: "components/owner-remote-search" */).then(c => wrapFunctional(c.default || c))
export const OwnerSaveButton = () => import('../../components/owner/SaveButton.vue' /* webpackChunkName: "components/owner-save-button" */).then(c => wrapFunctional(c.default || c))
export const OwnerSaveForm = () => import('../../components/owner/SaveForm.vue' /* webpackChunkName: "components/owner-save-form" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoCard = () => import('../../components/product/InfoCard.vue' /* webpackChunkName: "components/product-info-card" */).then(c => wrapFunctional(c.default || c))
export const ProductOwnerBindForm = () => import('../../components/product/OwnerBindForm.vue' /* webpackChunkName: "components/product-owner-bind-form" */).then(c => wrapFunctional(c.default || c))
export const ProductOwnerSaveButton = () => import('../../components/product/OwnerSaveButton.vue' /* webpackChunkName: "components/product-owner-save-button" */).then(c => wrapFunctional(c.default || c))
export const ProductOwnersButton = () => import('../../components/product/OwnersButton.vue' /* webpackChunkName: "components/product-owners-button" */).then(c => wrapFunctional(c.default || c))
export const ProductRemoteSearchInput = () => import('../../components/product/RemoteSearchInput.vue' /* webpackChunkName: "components/product-remote-search-input" */).then(c => wrapFunctional(c.default || c))
export const ProductSaveButton = () => import('../../components/product/SaveButton.vue' /* webpackChunkName: "components/product-save-button" */).then(c => wrapFunctional(c.default || c))
export const ProductSaveForm = () => import('../../components/product/SaveForm.vue' /* webpackChunkName: "components/product-save-form" */).then(c => wrapFunctional(c.default || c))
export const ProductUnitSelector = () => import('../../components/product/UnitSelector.vue' /* webpackChunkName: "components/product-unit-selector" */).then(c => wrapFunctional(c.default || c))
export const SearchBanner = () => import('../../components/search/Banner.vue' /* webpackChunkName: "components/search-banner" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/search/Input.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const SearchResultTile = () => import('../../components/search/ResultTile.vue' /* webpackChunkName: "components/search-result-tile" */).then(c => wrapFunctional(c.default || c))
export const SellerSaveButton = () => import('../../components/seller/SaveButton.vue' /* webpackChunkName: "components/seller-save-button" */).then(c => wrapFunctional(c.default || c))
export const ShapeSaveButton = () => import('../../components/shape/SaveButton.vue' /* webpackChunkName: "components/shape-save-button" */).then(c => wrapFunctional(c.default || c))
export const ShapeSelector = () => import('../../components/shape/Selector.vue' /* webpackChunkName: "components/shape-selector" */).then(c => wrapFunctional(c.default || c))
export const SubscribeGroupButton = () => import('../../components/subscribe-group/button.vue' /* webpackChunkName: "components/subscribe-group-button" */).then(c => wrapFunctional(c.default || c))
export const UiCardHeader = () => import('../../components/ui/CardHeader.vue' /* webpackChunkName: "components/ui-card-header" */).then(c => wrapFunctional(c.default || c))
export const UiFooter = () => import('../../components/ui/Footer.vue' /* webpackChunkName: "components/ui-footer" */).then(c => wrapFunctional(c.default || c))
export const UiHeader = () => import('../../components/ui/Header.vue' /* webpackChunkName: "components/ui-header" */).then(c => wrapFunctional(c.default || c))
export const UiLeftMenu = () => import('../../components/ui/LeftMenu.vue' /* webpackChunkName: "components/ui-left-menu" */).then(c => wrapFunctional(c.default || c))
export const UiLogo = () => import('../../components/ui/Logo.vue' /* webpackChunkName: "components/ui-logo" */).then(c => wrapFunctional(c.default || c))
export const UiPageHeader = () => import('../../components/ui/PageHeader.vue' /* webpackChunkName: "components/ui-page-header" */).then(c => wrapFunctional(c.default || c))
export const UiPagenationLink = () => import('../../components/ui/PagenationLink.vue' /* webpackChunkName: "components/ui-pagenation-link" */).then(c => wrapFunctional(c.default || c))
export const UiView = () => import('../../components/ui/View.vue' /* webpackChunkName: "components/ui-view" */).then(c => wrapFunctional(c.default || c))
export const UsersGroupSelector = () => import('../../components/users/GroupSelector.vue' /* webpackChunkName: "components/users-group-selector" */).then(c => wrapFunctional(c.default || c))
export const UsersSaveButton = () => import('../../components/users/SaveButton.vue' /* webpackChunkName: "components/users-save-button" */).then(c => wrapFunctional(c.default || c))
export const UsersStatusButton = () => import('../../components/users/StatusButton.vue' /* webpackChunkName: "components/users-status-button" */).then(c => wrapFunctional(c.default || c))
export const UsersStatusSelection = () => import('../../components/users/StatusSelection.vue' /* webpackChunkName: "components/users-status-selection" */).then(c => wrapFunctional(c.default || c))
export const WarehouseSaveButton = () => import('../../components/warehouse/SaveButton.vue' /* webpackChunkName: "components/warehouse-save-button" */).then(c => wrapFunctional(c.default || c))
export const WarehouseSelector = () => import('../../components/warehouse/Selector.vue' /* webpackChunkName: "components/warehouse-selector" */).then(c => wrapFunctional(c.default || c))
export const ClueOwnerBindButton = () => import('../../components/clue/owner/BindButton.vue' /* webpackChunkName: "components/clue-owner-bind-button" */).then(c => wrapFunctional(c.default || c))
export const ClueUserSaveButton = () => import('../../components/clue/user/SaveButton.vue' /* webpackChunkName: "components/clue-user-save-button" */).then(c => wrapFunctional(c.default || c))
export const ClueUserTypeLabel = () => import('../../components/clue/user/TypeLabel.vue' /* webpackChunkName: "components/clue-user-type-label" */).then(c => wrapFunctional(c.default || c))
export const ClueProductBindButton = () => import('../../components/clue/product/BindButton.vue' /* webpackChunkName: "components/clue-product-bind-button" */).then(c => wrapFunctional(c.default || c))
export const ClueProductListCreateButton = () => import('../../components/clue/product/ListCreateButton.vue' /* webpackChunkName: "components/clue-product-list-create-button" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
