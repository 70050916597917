
export default {
  data() {
    return {
      active: '2',
      menu: [
        {
          name: '总览',
          icon: 'el-icon-menu',
          path: "/manager",
        },
        {
          name: '订单创建',
          icon: 'el-icon-edit',
          path: "/manager/setup",
        },
        {
          name: '订单',
          icon: 'el-icon-chat-dot-round',
          children: [
            {
              name: '内部订单管理',
              path: "/manager/clue",
            },
            {
              name: '外部订单',
              path: "/manager/clue/user",
            },
          ]
        },
        {
          name: '货主',
          icon: 'el-icon-receiving',
          children: [
            {
              name: '货主管理',
              path: "/manager/owner/list",
            },
            {
              name: '货主持仓',
              path: "/manager/owner/holding",
            },
          ]
        },
        {
          name: '产品',
          icon: 'el-icon-box',
          children: [
            {
              name: '产品管理',
              path: "/manager/product/list",
            },
            {
              name: '工艺配置',
              path: "/manager/product/craft",
            },
            {
              name: '外观配置',
              path: "/manager/product/shape",
            },
          ]
        },
        {
          name: '用户',
          icon: 'el-icon-user',
          children: [
            {
              name: '用户管理',
              path: "/manager/users/list",
            },
            {
              name: '销售展示页配置',
              path: "/manager/users/seller",
            },
            {
              name: '个人资料',
              path: "/manager/users/profiles",
            },
          ]
        },
        {
          name: '品牌',
          icon: 'el-icon-receiving',
          children: [
            {
              name: '品牌管理',
              path: "/manager/brand/list",
            },
            // {
            //   name: '分类管理',
            //   path: "/manager/brand/categories",
            // },
          ]
        },
        {
          name: '系统',
          icon: 'el-icon-set-up',
          path: "/manager/site/setting"
        },
        //{
        //   name: '公告',
        //   icon: 'el-icon-chat-dot-square',
        //   path: "/manager/announcement/list"
        // },
        {
          name: '通知',
          icon: 'el-icon-bell',
          path: "/manager/notification/list"
        },
        {
          name: '仓储',
          icon: 'el-icon-sell',
          path: "/manager/warehouse/list"
        },
      ]
    }
  },
  methods: {
    handleClick(m) {
      const { $route } = this;
      if ($route.path == m.path) {
        return;
      }

      // 外部链接
      if (m.external) {
        const win = window.open();
        win.location.href = m.path;
        return;
      }

      // 内部链接
      this.$router.push(m.path);
    }
  }
}
