import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=66938b24&scoped=true&lang=pug&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=66938b24&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66938b24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiView: require('/Users/virs/Chayou/chenchacang_web/components/ui/View.vue').default})
