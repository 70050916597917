import axios from "axios";
import isEmpty from 'lodash/isEmpty';
import AuthHelper from "~/utils/request/auth";
import { URLS } from '~/utils/request/requestUrls';
import toast from '~/utils/toast';

/// axios 实例
const axiosApiInstance = axios.create();

/**
 * 接口请求超时时间设置
 */
const timeout = 1000 * 60;

/**
 * 默认提交的content-type
 */
const defaultContentType = "application/json";

/**
 * formdata
 */
const formDataContentType = "multipart/form-data";

/**
 * 跨域配置
 */
axiosApiInstance.defaults.withCredentials = false;

//-Request interceptor for API calls
//-自动补全票据
axiosApiInstance.interceptors.request.use(
  async config => {
    //- 请求地址
    let url = new URL(config.url);

    const token = new AuthHelper().getLocalStoredToken();
    let headers = {
    }

    //- 补全token
    if (!isEmpty(token) && typeof token.accessToken !== "undefined") {
      headers["Authorization"] = `Bearer ${token.accessToken}`;
    }

    //- 补全origin
    if (process.server) {
      headers["referer"] = url.origin;
    }
    config.headers = headers;

    //- 重置
    config.url = url.toString();
    return config;
  },
  error => {
    Promise.reject(error)
  });



//-Response interceptor for API calls
//-自动刷新token
axiosApiInstance.interceptors.response.use((response) => {
  // 检测接口是否启用了加密响应，如果启用了，那么就要在返回到前端前进行解密
  const encoded = response.data.encoded;
  if (typeof encoded === "boolean" && encoded) {
   
    // 解除混淆
    let sourceData = response.data.data;
    sourceData = sourceData.substr(10, sourceData.length);

    // 解码
    const decodeResponseData = Buffer.from(sourceData, 'base64').toString('utf-8');
    // 用完就丢掉
    sourceData = null;
    
    // 转为对象
    response.data.data = JSON.parse(decodeResponseData);
  }

  return response
}, async function (error) {
  const originalRequest = error.config;

  if (typeof error.response === "undefined") {
    //- 提示链接错误 注意showErrors({message: ''}) 传入object
    const message = error.message || "客户端未知的请求错误";
    Request.getInstance().showErrors({ message });
    return Promise.reject(error);
  }

  // 状态码
  const statusCode = error.response.status;

  if (statusCode === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const token = await Request.getInstance().refreshAccessToken();
    if (!token) {
      await Request.getInstance().redirectLogin();
      return Promise.reject(Error("failed to refresh token."));
    }

    //-保存刷新后的toKen
    new AuthHelper().saveToken(token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accessToken;
    return axiosApiInstance(originalRequest);
  }


  //- 处理接口返回的错误提示
  //- restful 用到的成功状态码204和200
  if (statusCode != 200 && statusCode != 204) {

    // 502的错误提示单独处理
    if (statusCode != 502) {
      Request.getInstance().showErrors(error.response.data || null);
    }

    // 如果是设备信息不对称,那么将本地存储的token清除
    if (statusCode == 418) {
      new AuthHelper().clear();
    }

    // 502提示
    if (statusCode == 502 || statusCode == 503) {
      Request.getInstance().showErrors({
        message: "服务器可能正在进行临时维护，请稍等"
      });
    }
  }

  return Promise.reject(error);
});


class Request {
  constructor() {
    this.instance = null;
  }

  /**
   * 获取静态实例
   * @returns instance
   */
  static getInstance() {
    if (!this.instance) {
      this.instance = new Request();
    }
    return this.instance;
  }

  /**
   * 跳转到登录页
   */
  async redirectLogin() {
    if (!process.client) {
      return;
    }

    toast.show({
      message: '您的登录状态已经过期，请重新登录后继续',
      type: 'warning'
    });
  }

  /**
   * refresh access token
   * @param {*} opts 
   * @returns response
   */
  async refreshAccessToken() {
    const token = new AuthHelper().getLocalStoredToken();
    if (isEmpty(token)) {
      return null;
    }

    const refreshToken = `Bearer ${token.refreshToken}`;
    const resp = await this.postJson({
      url: URLS.USERS_REFRESH_TOKEN, data: {
        refreshToken
      }
    });
    const rs = resp.data;

    return rs.data || null;
  }

  /**
   * 
   * @param {*} opts 
   * @returns response
   */
  get(options, cancelToken) {
    return axiosApiInstance.get(
      options.url,
      {
        ...options.data,
      },
      {
        timeout,
        cancelToken: cancelToken === undefined ? null : cancelToken
      });
  }

  /**
   * post josn
   * @param {*} opts 
   * @returns response
   */
  postJson(options, cancelToken) {
    return axiosApiInstance.post(options.url,
      {
        ...options.data,
      },
      {
        headers: {
          'Content-Type': defaultContentType
        },
        cancelToken: cancelToken === undefined ? null : cancelToken,
        timeout,
      });
  }


  /**
   * upload files
   * @param {*} opts 
   * @returns response
   */
  uploadFile(options, cancelToken) {
    
    return axiosApiInstance.post(options.url, options.formData,
      {
        headers: {
          'Content-Type': formDataContentType
        },
        cancelToken: cancelToken === undefined ? null : cancelToken,
        timeout,
      });
  }

  /**
   * delete
   * @param {*} opts 
   * @returns response
   */
  delete(options, cancelToken) {
    return axiosApiInstance.delete(options.url,
      {
        ...options.data,
      },
      {
        cancelToken: cancelToken === undefined ? null : cancelToken,
        timeout
      });
  }

  /**
   * patch
   * @param {*} opts 
   * @returns response
   */
  patch(options, cancelToken) {
    return axiosApiInstance.patch(options.url,
      {
        ...options.data
      },
      {
        headers: {
          'Content-Type': defaultContentType
        },
        cancelToken: cancelToken === undefined ? null : cancelToken,
        timeout
      }
    );
  }


  /**
   * 提示错误
   * data = {message: "错误提示", errors: {...服务端返回错误}} message优先级最高，其次显示 errors
   * @param {*} data 
   * void
   */
  showErrors(data) {

    //-处理和拼接错误字符串
    let message = data.message || "";
    if (isEmpty(message)) {
      message = "未知错误"
    }

    if (process.server) {
      return;
    }

    toast.notify({
      type: "error",
      message
    });
  }
}

/// 单例
const req = new Request();

export default req;