import systemApi from "~/utils/api/system";

export const state = () => ({
  bootstrap: null,
  // 站点配置
  setSite: {},
  // 短信验证码可用验证类型
  verifyTypes: [],
  // 腾讯云配置
  qcloud: {},
  // 投诉建议类型
  reportTypes: [],
  // 用户状态字典
  userStatusDicts: [],
  // 产品单位
  units: [],
  // 产品工艺
  productCrafts: [],
  // 产品外观
  productShapes: [],
  // 用户组
  groups: [],
  // 内部订单状态字典
  clueStatusDict: [],
  // clueTypes 内部订单类型
  clueTypes: {},
  // 仓储
  warehouses: [],
})

export const mutations = {
  //- update bootstrap information
  updateBootstrap(state, bootstrap) {
    state.bootstrap = bootstrap;

    /// 更新bootstrap时同时更新setSite
    /// 更新bootstrap同时更新verifyTypes
    /// 更新bootstrap同时更新qcloud
    if (typeof bootstrap === "object") {
      state.setSite = bootstrap.setSite || {};
      state.qcloud = bootstrap.qcloud || {};
      state.verifyTypes = bootstrap.verifyTypes || [];
      state.reportTypes = bootstrap.reportTypes || [];
      state.userStatusDicts = bootstrap.userStatusDicts || [];
      state.units = bootstrap.units || [];
      state.productCrafts = bootstrap.productCrafts || [];
      state.productShapes = bootstrap.productShapes || [];
      state.groups = bootstrap.groups || [];
      state.clueStatusDict = bootstrap.clueStatusDict || [];
      state.clueTypes = bootstrap.clueTypes || {};
      state.warehouses = bootstrap.warehouses || [];
    }
  },

}

export const actions = {
  //- 启动配置
  async bootstrap(context) {
    const rs = await systemApi.bootstrap();
    if (rs) {
      context.commit("updateBootstrap", rs.data);
      return true;
    }
    return;
  },

}

export const namespaced = true;
