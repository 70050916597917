import brandApi from '~/utils/api/brand';

export const state = () => ({
  /// 品牌列表
  brands: [],
  /// 是否正在加载品牌列表
  loadingBrands: true,
})


export const mutations = {
  //- update bootstrap information
  updateBrands(state, brands) {
    state.brands = brands || [];
  },
  //- 正在加载用户状态
  updateLoadingBrands(state, loading) {
    state.loadingBrands = loading;
  },
}


export const actions = {
  /**
   * 更新品牌列表
   * @param {*} context 
   * @returns 
   */
  async updateBrandsList(context) {

    context.commit("updateLoadingBrands", true);

    try {
      const rs = await brandApi.list({
        limit: 400
      });
      if (rs) {
        context.commit("updateBrands", rs.data);
        context.commit("updateLoadingBrands", false);
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    context.commit("updateLoadingBrands", false);
    return;
  },
}

export const namespaced = true;