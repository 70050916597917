import Common from '~/utils/common';
import app from '~/nuxt.app';

/// 生产和开发环境不同，上线时注意配置
let baseUrl = Common.isProduction ? app.apiBaseUrl : 'http://localhost:3088/api/v1';
/// 将服务端渲染转发到内网
if (!Common.isClient && Common.isProduction) {
  baseUrl = app.apiInternalBaseUrl;
}

/// 导出
export const URLS = {

  /// Bootstrap
  BOOTSTRAP: `${baseUrl}/system/bootstrap`,
  SYSTEM_SERVER_TIME: `${baseUrl}/system/servertime`,
  SYSTEM_SET_SITE: `${baseUrl}/system/setsite`,
  SYSTEM_OVERVIEW: `${baseUrl}/system/overview`,

  /// 用户
  /// 用户名密码登录
  USERS_PASSWORD_LOGIN: `${baseUrl}/users/login`,
  USERS_SMS_LOGIN: `${baseUrl}/users/login/sms`,
  USERS_INFO: `${baseUrl}/users/current`,
  USERS_REFRESH_TOKEN: `${baseUrl}/users/refresh-token`,
  USERS_DETAIL: `${baseUrl}/users/detail`,
  USERS_CREATE: `${baseUrl}/users/create`,
  USERS_ADD: `${baseUrl}/users/add`,
  USERS_NAME_VALIDATE: `${baseUrl}/users/name-validate`,
  USERS_RESET_PASSWORD: `${baseUrl}/users/reset_password`,
  USERS_LIST: `${baseUrl}/users/list`,
  USERS_STATUS: `${baseUrl}/users/status`,
  USERS_PROFILES: `${baseUrl}/users/profiles`,
  USERS_PROFILES_MODIFY: `${baseUrl}/users/profiles/modify`,
  USERS_GROUP_MODIFY: `${baseUrl}/users/group/modify`,
  USER_RENAME: `${baseUrl}/users/rename`,

  // 小程序相关接口
  MP_WEIXIN_LOGIN: `${baseUrl}/mp/wx/login`,
  MP_WEIXIN_BIND: `${baseUrl}/mp/wx/bind`,
  MP_WEIXIN_UNBIND: `${baseUrl}/mp/wx/unbind`,

  // 公众号
  MP_WX_GZH_BIND: `${baseUrl}/mp/wx/gzh/bind`,
  MP_WX_GZH_LOGIN: `${baseUrl}/mp/wx/gzh/login`,



  /// 品牌分类
  BRANDS_CATEGORIES_CACHED: `${baseUrl}/brands/categories-cached`,
  BRANDS_CATEGORIES: `${baseUrl}/brands/categories`,
  BRANDS_CATEGORIES_CREATE: `${baseUrl}/brands/categories/create`,
  BRANDS_CATEGORIES_UPDATE: `${baseUrl}/brands/categories/update`,
  BRANDS_CATEGORIES_DELETE: `${baseUrl}/brands/categories/delete`,

  // 创建品牌
  BRANDS_SAVE: `${baseUrl}/brands/save`,
  BRANDS_DELTE: `${baseUrl}/brands/delete`,
  BRANDS_LIST: `${baseUrl}/brands/list`,
  BRANDS_DETAIL: `${baseUrl}/brands/detail`,

  // 产品
  PRODUCT_SAVE: `${baseUrl}/product/save`,
  PRODUCT_DELETE: `${baseUrl}/product/delete`,
  PRODUCT_DISPLAY: `${baseUrl}/product/display`,
  PRODUCT_DETAIL: `${baseUrl}/product/detail`,
  PRODUCT_LIST: `${baseUrl}/product/list`,
  PRODUCT_EXITS: `${baseUrl}/product/exits`,

  // 产品分类
  PRODUCT_CATEGORIES: `${baseUrl}/product/categories`,
  PRODUCT_CATEGORIES_CREATE: `${baseUrl}/product/categories/save`,
  PRODUCT_CATEGORIES_DELETE: `${baseUrl}/product/categories/delete`,

  // 产品工艺
  PRODUCT_CRAFTS: `${baseUrl}/product/crafts`,
  PRODUCT_CRAFT_SAVE: `${baseUrl}/product/craft/save`,
  PRODUCT_CRAFT_DELETE: `${baseUrl}/product/craft/delete`,

  // 产品货主关联
  PRODUCT_OWNER_BIND: `${baseUrl}/product/owner/bind`,
  PRODUCT_OWNER_UNBIND: `${baseUrl}/product/owner/unbind`,
  PRODUCT_OWNER_LIST: `${baseUrl}/product/owner/list`,

  // 产品外观
  PRODUCT_SHAPES: `${baseUrl}/product/shapes`,
  PRODUCT_SHAPE_SAVE: `${baseUrl}/product/shape/save`,
  PRODUCT_SHAPE_DELETE: `${baseUrl}/product/shape/delete`,

  // 货主
  OWNER_LIST: `${baseUrl}/owner/list`,
  OWNER_SAVE: `${baseUrl}/owner/save`,
  OWNER_DELETE: `${baseUrl}/owner/delete`,

  // 地址
  USER_ADDRESS_LIST: `${baseUrl}/users/address/list`,
  USER_ADDRESS_DELETE: `${baseUrl}/users/address/delete`,
  USER_ADDRESS_SAVE: `${baseUrl}/users/address/save`,

  /// 短信
  SMS_SEND: `${baseUrl}/sms/send`,

  //---文件系统-----
  FS_CREDENTIAL: `${baseUrl}/fs/credential`,
  FS_DOWNLOAD_IMAGE: `${baseUrl}/fs/download_image`,

  /// 举报
  REPORT_CREATE: `${baseUrl}/report/create`,
  REPORT_LIST: `${baseUrl}/report/list`,
  REPORT_MARK: `${baseUrl}/report/mark`,

  /// 公告
  ANNOUNCEMENT_CREATE: `${baseUrl}/announcement/create`,
  ANNOUNCEMENT_DELETE: `${baseUrl}/announcement/delete`,
  ANNOUNCEMENT_DETAIL: `${baseUrl}/announcement/detail`,
  ANNOUNCEMENT_LIST: `${baseUrl}/announcement/list`,

  // 搜索
  SEARCH_SYNC_PUBLIC: `${baseUrl}/search/sync/public`,
  SEARCH_DO: `${baseUrl}/search/do`,

  // 内部订单
  CLUE_DETAIL: `${baseUrl}/clue/detail`,
  CLUE_LITE_DETAIL: `${baseUrl}/clue/detail/lite`,
  CLUE_SAVE: `${baseUrl}/clue/save`,
  CLUE_DELETE: `${baseUrl}/clue/delete`,
  CLUE_LIST: `${baseUrl}/clue/list`,
  CLUE_LIST_LITE: `${baseUrl}/clue/list/lite`,
  CLUE_STATUS: `${baseUrl}/clue/status`,
  CLUE_OWNER_BIND: `${baseUrl}/clue/owner/bind`,
  CLUE_PRODUCT_BIND: `${baseUrl}/clue/product/bind`,
  CLUE_WAREHOUSE_BIND: `${baseUrl}/clue/warehouse/bind`,
  CLUE_HISTORY_SAVE: `${baseUrl}/clue/history/save`,
  CLUE_HISTORY_DELETE: `${baseUrl}/clue/history/delete`,
  CLUE_CLAIM: `${baseUrl}/clue/claim`,
  CLUE_PROTECTED: `${baseUrl}/clue/protected`,


  // 用户匿名提交内部订单
  CLUE_USER_DELETE: `${baseUrl}/clue/user/delete`,
  CLUE_USER_LIST: `${baseUrl}/clue/user/list`,
  CLUE_USER_SAVE: `${baseUrl}/clue/user/save`,
  CLUE_USER_STATUS: `${baseUrl}/clue/user/status`,

  // 通知
  NOTIFICATION_LIST: `${baseUrl}/notification/list`,
  NOTIFICATION_SAVE: `${baseUrl}/notification/save`,
  NOTIFICATION_DELETE: `${baseUrl}/notification/delete`,

  // 仓储
  WAREHOUSE_LIST: `${baseUrl}/warehouse/list`,
  WAREHOUSE_SAVE: `${baseUrl}/warehouse/save`,
  WAREHOUSE_DELETE: `${baseUrl}/warehouse/delete`,

  // 销售展示
  SELLER_LIST: `${baseUrl}/seller/list`,
  SELLER_SAVE: `${baseUrl}/seller/save`,
  SELLER_DELETE: `${baseUrl}/seller/delete`,

  // 通知订阅分组
  CLUE_USER_SUBSCRIBE_GROUP_LIST: `${baseUrl}/subscribe/group/list`,
  CLUE_USER_SUBSCRIBE_GROUP_SAVE: `${baseUrl}/subscribe/group/save`,
  CLUE_USER_SUBSCRIBE_GROUP_DELETE: `${baseUrl}/subscribe/group/delete`,
};