
export default {
  data() {
    return {}
  },
  methods: {
    handleClick(e){
      this.$emit('click', e);
    }
  }
}
