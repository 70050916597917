import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  //- 使用手机号和密码进行登录
  async passworLogin(data) {
    const rs = await req.postJson({
      url: URLS.USERS_PASSWORD_LOGIN,
      data
    });
    return rs.data;
  },
  //- 使用短信验证码登录
  async smsLogin(data) {
    const rs = await req.postJson({
      url: URLS.USERS_SMS_LOGIN,
      data
    });
    return rs.data;
  },
  //- 当前登录的用户信息
  async info() {
    const rs = await req.get({
      url: URLS.USERS_INFO
    });
    return rs.data;
  },
  /**
   * 用户注册
   * @param {*} data 
   * @returns 
   */
  async create(data) {
    const rs = await req.postJson({
      url: URLS.USERS_CREATE,
      data
    });
    return rs.data;
  },
  /**
   * 新增用户
   * @param {*} data 
   * @returns 
   */
  async add(data) {
    const rs = await req.postJson({
      url: URLS.USERS_ADD,
      data
    });
    return rs.data;
  },
  /**
   * 取指定用户详细信息
   * @param {*} id 
   * @returns 
   */
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.USERS_DETAIL}/${id}`
    });
    return rs.data;
  },
  /**
   * 检测用户名是否可用
   * @param {*} data 
   * @returns 
   */
  async nameValidate(data) {
    const rs = await req.postJson({
      url: URLS.USERS_NAME_VALIDATE,
      data
    });
    return rs.data;
  },
  /**
   * 重置密码
   * @param {*} data 
   * @returns 
   */
  async resetPassword(data) {
    const rs = await req.postJson({
      url: URLS.USERS_RESET_PASSWORD,
      data
    });
    return rs.data;
  },
  /**
   * 用户列表
   * @param {*} data 
   * @returns 
   */
  async list(data) {
    const rs = await req.postJson({
      url: URLS.USERS_LIST,
      data
    });
    return rs.data;
  },
  /**
   * 更新用户状态
   * @param {*} data 
   * @returns 
   */
  async status(data) {
    const rs = await req.postJson({
      url: URLS.USERS_STATUS,
      data
    });
    return rs.data;
  },
  /**
   * 更新个人资料
   * @param {*} data 
   * @returns 
   */
  async profilesModify(data) {
    const rs = await req.postJson({
      url: URLS.USERS_PROFILES_MODIFY,
      data
    });
    return rs.data;
  },
  /**
   * 取个人资料
   * @returns 
   */
  async profiles() {
    const rs = await req.get({
      url: URLS.USERS_PROFILES
    });
    return rs.data;
  },
  /**
   * 保存地址
   */
  async addressSave(data) {
    const rs = await req.postJson({
      url: URLS.USER_ADDRESS_SAVE,
      data
    });
    return rs.data;
  },
  /**
   * 分页查询收货地址
   */
  async addressList(data) {
    const rs = await req.postJson({
      url: URLS.USER_ADDRESS_LIST,
      data
    });
    return rs.data;
  },
  /**
   * 删除收货地址
   * @param {*} id 
   * @returns 
   */
  async addressDelete(id) {
    const rs = await req.get({
      url: `${URLS.USER_ADDRESS_DELETE}/${id}`
    });
    return rs.data;
  },
  /**
   * 取消申请媒体号
   * @returns 
   */
  async mediumDelete() {
    const rs = await req.get({
      url: URLS.USER_MEDIUM_DELETE
    });
    return rs.data;
  },
  /**
   * 提交申请媒体号或更新
   * @param {*} data 
   * @returns 
   */
  async mediumSave(data) {
    const rs = await req.postJson({
      url: URLS.USER_MEDIUM_SAVE,
      data
    });
    return rs.data;
  },
  /**
   * 取当前媒体号详情
   * @returns 
   */
  async mediumDetail() {
    const rs = await req.get({
      url: URLS.USER_MEDIUM_DETAIL
    });
    return rs.data;
  },
  /**
   * 审核媒体号
   * @param {*} data 
   * @returns 
   */
  async mediumReview(data) {
    const rs = await req.postJson({
      url: URLS.USER_MEDIUM_REVIEW,
      data
    });
    return rs.data;
  },
  /**
   * 审核媒体号列表
   * @param {*} data 
   * @returns 
   */
  async mediumReviewList(data) {
    const rs = await req.postJson({
      url: URLS.USER_MEDIUM_REVIEW_LIST,
      data
    });
    return rs.data;
  },
  /**
   * 取得用户钱包详情
   * @returns 
   */
  async WalletDetail() {
    const rs = await req.get({
      url: URLS.USER_WALLET_DETAIL
    });
    return rs.data;
  },
  /**
   * 修改用户组
   * @param {*} data 
   * @returns 
   */
  async groupModify(data) {
    const rs = await req.postJson({
      url: URLS.USERS_GROUP_MODIFY,
      data
    });
    return rs.data;
  },
  /**
   * 修改用户名
   * @param {*} data 
   * @returns 
   */
  async rename(data) {
    const rs = await req.postJson({
      url: URLS.USER_RENAME,
      data
    });
    return rs.data;
  },
}