module.exports = {
  // 应用名称
  name: "陈茶仓",

  // 不携带协议的主机名
  host: "huo.chayou.com",

  // base host
  baseHost: "https://huo.chayou.com",

  // 接口地址
  apiBaseUrl: "https:/huo.chayou.com/api/v1",

  // 生产环境下的接口URL，用于内网渲染
  // apiInternalBaseUrl: 'http://127.0.0.1:1236/api/v1',
  apiInternalBaseUrl: 'https:/huo.chayou.com/api/v1',
  /**
   * 取网页标题
   * @param {*} t 
   * @returns 
   */
  getTitle(t) {
    const { name } = this;
    return `${t || '未知页面'} - ${name}`;
  },
}