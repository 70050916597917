import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eee54912 = () => interopDefault(import('../pages/manager/index.vue' /* webpackChunkName: "pages/manager/index" */))
const _7e32a7f4 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _7bfa710b = () => interopDefault(import('../pages/seller/index.vue' /* webpackChunkName: "pages/seller/index" */))
const _052bc0b2 = () => interopDefault(import('../pages/manager/clue/index.vue' /* webpackChunkName: "pages/manager/clue/index" */))
const _158f9fa2 = () => interopDefault(import('../pages/manager/setup.vue' /* webpackChunkName: "pages/manager/setup" */))
const _a1831da8 = () => interopDefault(import('../pages/users/create.vue' /* webpackChunkName: "pages/users/create" */))
const _5227f6e9 = () => interopDefault(import('../pages/users/login.vue' /* webpackChunkName: "pages/users/login" */))
const _3142c1cf = () => interopDefault(import('../pages/users/reset.vue' /* webpackChunkName: "pages/users/reset" */))
const _3b9b058b = () => interopDefault(import('../pages/manager/announcement/list.vue' /* webpackChunkName: "pages/manager/announcement/list" */))
const _7147ef0f = () => interopDefault(import('../pages/manager/brand/categories.vue' /* webpackChunkName: "pages/manager/brand/categories" */))
const _be392e5e = () => interopDefault(import('../pages/manager/brand/list.vue' /* webpackChunkName: "pages/manager/brand/list" */))
const _e326aff4 = () => interopDefault(import('../pages/manager/clue/user.vue' /* webpackChunkName: "pages/manager/clue/user" */))
const _5850bb32 = () => interopDefault(import('../pages/manager/notification/list.vue' /* webpackChunkName: "pages/manager/notification/list" */))
const _30c172e8 = () => interopDefault(import('../pages/manager/owner/holding.vue' /* webpackChunkName: "pages/manager/owner/holding" */))
const _45b51325 = () => interopDefault(import('../pages/manager/owner/list.vue' /* webpackChunkName: "pages/manager/owner/list" */))
const _502f53a5 = () => interopDefault(import('../pages/manager/product/craft.vue' /* webpackChunkName: "pages/manager/product/craft" */))
const _792983a9 = () => interopDefault(import('../pages/manager/product/list.vue' /* webpackChunkName: "pages/manager/product/list" */))
const _d430ceb4 = () => interopDefault(import('../pages/manager/product/shape.vue' /* webpackChunkName: "pages/manager/product/shape" */))
const _5ca69073 = () => interopDefault(import('../pages/manager/site/setting.vue' /* webpackChunkName: "pages/manager/site/setting" */))
const _15836770 = () => interopDefault(import('../pages/manager/users/list.vue' /* webpackChunkName: "pages/manager/users/list" */))
const _fdf6a088 = () => interopDefault(import('../pages/manager/users/profiles.vue' /* webpackChunkName: "pages/manager/users/profiles" */))
const _0340715e = () => interopDefault(import('../pages/manager/users/seller.vue' /* webpackChunkName: "pages/manager/users/seller" */))
const _35621b95 = () => interopDefault(import('../pages/manager/warehouse/list.vue' /* webpackChunkName: "pages/manager/warehouse/list" */))
const _994d9ee0 = () => interopDefault(import('../pages/mp/weixin/subscribe.vue' /* webpackChunkName: "pages/mp/weixin/subscribe" */))
const _0045da59 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0479dfcf = () => interopDefault(import('../pages/manager/clue/_id.vue' /* webpackChunkName: "pages/manager/clue/_id" */))
const _0b6f5dad = () => interopDefault(import('../pages/clue/_id.vue' /* webpackChunkName: "pages/clue/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/manager",
    component: _eee54912,
    name: "manager"
  }, {
    path: "/search",
    component: _7e32a7f4,
    name: "search"
  }, {
    path: "/seller",
    component: _7bfa710b,
    name: "seller"
  }, {
    path: "/manager/clue",
    component: _052bc0b2,
    name: "manager-clue"
  }, {
    path: "/manager/setup",
    component: _158f9fa2,
    name: "manager-setup"
  }, {
    path: "/users/create",
    component: _a1831da8,
    name: "users-create"
  }, {
    path: "/users/login",
    component: _5227f6e9,
    name: "users-login"
  }, {
    path: "/users/reset",
    component: _3142c1cf,
    name: "users-reset"
  }, {
    path: "/manager/announcement/list",
    component: _3b9b058b,
    name: "manager-announcement-list"
  }, {
    path: "/manager/brand/categories",
    component: _7147ef0f,
    name: "manager-brand-categories"
  }, {
    path: "/manager/brand/list",
    component: _be392e5e,
    name: "manager-brand-list"
  }, {
    path: "/manager/clue/user",
    component: _e326aff4,
    name: "manager-clue-user"
  }, {
    path: "/manager/notification/list",
    component: _5850bb32,
    name: "manager-notification-list"
  }, {
    path: "/manager/owner/holding",
    component: _30c172e8,
    name: "manager-owner-holding"
  }, {
    path: "/manager/owner/list",
    component: _45b51325,
    name: "manager-owner-list"
  }, {
    path: "/manager/product/craft",
    component: _502f53a5,
    name: "manager-product-craft"
  }, {
    path: "/manager/product/list",
    component: _792983a9,
    name: "manager-product-list"
  }, {
    path: "/manager/product/shape",
    component: _d430ceb4,
    name: "manager-product-shape"
  }, {
    path: "/manager/site/setting",
    component: _5ca69073,
    name: "manager-site-setting"
  }, {
    path: "/manager/users/list",
    component: _15836770,
    name: "manager-users-list"
  }, {
    path: "/manager/users/profiles",
    component: _fdf6a088,
    name: "manager-users-profiles"
  }, {
    path: "/manager/users/seller",
    component: _0340715e,
    name: "manager-users-seller"
  }, {
    path: "/manager/warehouse/list",
    component: _35621b95,
    name: "manager-warehouse-list"
  }, {
    path: "/mp/weixin/subscribe",
    component: _994d9ee0,
    name: "mp-weixin-subscribe"
  }, {
    path: "/",
    component: _0045da59,
    name: "index"
  }, {
    path: "/manager/clue/:id",
    component: _0479dfcf,
    name: "manager-clue-id"
  }, {
    path: "/clue/:id?",
    component: _0b6f5dad,
    name: "clue-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
