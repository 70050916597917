import systemApi from '~/utils/api/system';

export const state = () => ({
  /// 品牌列表
  overviews: [],
  /// 是否正在加载品牌列表
  loadingOverviews: true,
})


export const mutations = {
  //- update bootstrap information
  updateOverviews(state, overviews) {
    state.overviews = overviews || [];
  },
  //- 正在加载用户状态
  updateLoadingOverviews(state, loading) {
    state.loadingOverviews = loading;
  },
}


export const actions = {
  /**
   * 更新品牌列表
   * @param {*} context 
   * @returns 
   */
  async updateOverviews(context) {

    //防止重复加载
    if (context.state.loadingOverviews === false) {
      return;
    }

    context.commit("updateLoadingOverviews", true);

    try {
      const rs = await systemApi.overview();
      if (rs) {
        context.commit("updateOverviews", rs.data);
        context.commit("updateLoadingOverviews", false);
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    context.commit("updateLoadingOverviews", false);
    return;
  },
}

export const namespaced = true;